<template>
  <v-container
    id="bamboo-board"
    fluid
    tag="section"
  >
    <v-card
      class="pa-5"
    >
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          class="mb-5"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-select
          v-model="filteredRisks"
          :items="riskItems"
          multiple
          class="max-100"
          label="Risk Level"
          chips
          deletable-chips
          @input="updateData"
        >
        </v-select>
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-model="selectedItems"
          :loading="loading"
          :headers="headers"
          :items="items"
          :items-per-page="page"
          item-key="id"
          :search="search"
          show-select
          @update:items-per-page="getPageNum"
        > 
          <template v-slot:item.email="{ item }">
            <span v-html="highlightText(item.email)"></span>
          </template>
          <template v-slot:item.assignee="{ item }">
            <span v-html="highlightText(item.assignee)"></span>
          </template>
          <template v-slot:item.description="{ item }">
            <span v-html="highlightText(item.description)"></span>
          </template>
          <template v-slot:item.created_at="{ item }">
            <span>{{ item.created_at | beautifyDateTime }}</span>
          </template>
          <template v-slot:item.due_at="{ item }">
            <span>{{ item.due_at | beautifyDateTime }}</span>
          </template>
          <template v-slot:item.has_2fa="{ item }">
            <v-chip :class="{'success': item.has_2fa}">{{item.has_2fa ? 'Yes' : 'No'}}</v-chip>
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip :class="{'success': item.status}">{{item.status ? 'Ok' : 'No'}}</v-chip>
          </template>
          <template v-slot:item.risk_level="{ item }">
            <v-chip :color="levelColor(item.risk_level)" dark>
              <div class="subtitle-2 text-capitalize">{{ item.risk_level || 'low' }}</div>
            </v-chip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import { BASE_API, Get, Post } from '@/api'
  import { downloadCSV, beautifyDateTime, highlightText, levelColor } from '@/util'
  import { mapState, mapActions } from 'vuex';

  export default {
    name: 'BambooBoard',

    data () {
      return {
        search: '',
        loading: false,
        items: [],
        itemsOrigin: [],
        selectedItems: [],
        filteredRisks:[],
        headers: [
          {
            text: 'Task',
            value: 'subject'
          },
          {
            text: 'Status',
            value: 'status'
          },
          {
            text: 'Description',
            value: 'description'
          },
          {
            text: 'Risk Level',
            value: 'risk_level'
          },
        ]
      }
    },

    mounted() {
      if (this.tempRisk) {
        this.filteredRisks = [...this.tempRisk]
        this.SET_TEMP_RISK([])
      }
      this.readTasks()
    },

    filters: {
      beautifyDateTime
    },

    computed: {
      ...mapState(['page', 'riskItems', 'tempRisk', 'companyId']),
    },

    methods: {
      ...mapActions(['showCronDialog', 'SET_TEMP_RISK']),

      highlightText,
      levelColor,

      async readTasks () {
        this.loading = true
        const res = await Get(`admin/tasks/read/tasks/${this.companyId}`)
        this.itemsOrigin = res.items
        this.updateData()
        this.loading = false
      },
      updateData () {
        this.items = this.itemsOrigin.filter(item => {
          if (this.filteredRisks.length) {
            let pattern = item.risk_level || 'low'
            pattern = pattern.toLowerCase().trim()
            if (pattern == 'critical') {
              pattern = /critical/i
            } else if (pattern == 'high') {
              pattern = /high/i
            } else if (pattern == 'medium') {
              pattern = /medium/i
            } else if (pattern == 'low') {
              pattern = /low/i
            } else if (pattern == 'informational') {
              pattern = /informational/i
            } 
            if (this.filteredRisks.join('').match(pattern)) {
              return item
            }
          } else {
            return item
          }
        })
      },
      getPageNum (_page) {
        localStorage.setItem('page', _page)
      },
    }
  }
</script>